import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { LocalService } from '@data-access/services';

@Injectable({ providedIn: 'root' })
export class SessionTimeoutService implements OnDestroy {
  private timeoutId: any;

  private readonly inactivityTimeout = 15 * 60 * 1000; // 15 minutes

  constructor(
    private ngZone: NgZone, 
    @Inject(PLATFORM_ID) private platformId: Object, 
    private local: LocalService, 
    private router: Router
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.resetTimer();
      this.setupActivityListeners();
    }
  }

  private setupActivityListeners() {
    if (!isPlatformBrowser(this.platformId)) return;

    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    events.forEach(event => {
      this.ngZone.runOutsideAngular(() => {
        document.addEventListener(event, () => this.resetTimer());
      });
    });
  }

  resetTimer() {
    this.ngZone.run(() => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => this.handleLogout(), this.inactivityTimeout);
    });
  }

  private handleLogout() {
    if (isPlatformBrowser(this.platformId)) {
      this.local.removeData("token");
      this.local.removeData("user");
    }
    this.router.navigate(['/login']);
    this.cleanup();
  }

  cleanup() {
    clearTimeout(this.timeoutId);
  }

  ngOnDestroy() {
    this.cleanup();
  }
}