export const Assets = {
    IMAGES: {
        DEFAULT_RESTAURANT_IMAGE: '/assets/default-restaurant.jpg',
        HERO_IMAGE: '/assets/hero-image-container.png'
    },
    ICONS: {
        MOTORBIKE_ICON: "/assets/icons/motorbike.svg",
        YELLOW_MOTORBIKE_ICON: "/assets/icons/yellow-motorbike.svg",
        SHOPPING_CART: "/assets/icons/shopping-cart.svg",
        USER_ICON: "/assets/icons/user.svg",
        ORDER_ICON: "/assets/icons/orders.svg",
        LOGOUT_ICON: "/assets/icons/logout.svg",
        MENU_OPEN_ICON: "/assets/icons/menu-open.svg",
        MENU_CLOSE_ICON: "/assets/icons/menu-close.svg",
    },
    LOGOS: {
        DARK_MODE_LOGO: "/assets/logos/logo_black.png",
        LIGHT_MODE_LOGO: "/assets/logos/logo_white.png"
    }
}