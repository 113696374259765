<header [ngClass]="{'border-b border-b-vendblocprimayGrey fixed z-[999]': !dashboardHeader,
 'dark bg-black': darkModeService.darkModeSignal() === 'dark' && !dashboardHeader,
  'null bg-white': darkModeService.darkModeSignal() === 'null' && !dashboardHeader,
}" class="w-full py-[15px] px-[30px]  xl:px-[50px] xl:py-[20px]">
  <div [class.hidden]="isMobile" class="justify-between items-center" [class.flex]="!dashboardHeader"
    [class.flex-end]="dashboardHeader">

    <!-- Logo -->
    @if(showLogo){
    <div>
      <a routerLink="/">
        <div class="flex justify-center items-center">
          <img [src]="darkModeService.darkModeSignal() === 'dark' ? LIGHT_MODE_LOGO : DARK_MODE_LOGO" class="h-6 md:h-8"
            alt="vendbloc_logo">
        </div>
      </a>
    </div>
    }

    <div class="flex flex-row justify-between items-center gap-x-20">



      <!-- Links -->
      <div class="flex-col">
        @if(!dashboardHeader){
        @if(currentRoute !== '/login' && currentRoute !== '/register' && currentRoute !== '/forgot-password' &&
        currentRoute !== '/request-verification'){
        <div class="flex flex-row items-center justify-center gap-x-5">

          <a class="text-primary" routerLinkActive="route-active" routerLink="/about">About Vendbloc</a>
          <a class="text-primary" routerLinkActive="route-active" routerLink="/explore">Restaurant</a>
          <a class="text-primary" routerLinkActive="route-active" routerLink="/faqs">FAQS</a>
        </div>

        }

        }
      </div>

      <!-- auth buttons and cart -->




      <div class="flex items-center gap-x-8">


        <!-- <i (click)="toggleComplaint()" class="pi pi-comment"></i> -->
        @if(showCart){
        <!-- Mobile cart icon (clickable) -->
        <!-- relative cursor-pointer pi pi-shopping-cart text-vendblocblue dark:text-white md:cursor-default -->
        <div class="relative cursor-pointer md:cursor-default" (click)="isMobile && cartEvent()"
          style="font-size: 25px;">
          <p-badge class="absolute -right-3 -top-6" [value]="cartCount" [style]="{'background-color':'#FFB800'}"
            severity="warning"></p-badge>
          <svg-icon [src]="SHOPPING_CART_ICON"></svg-icon>
        </div>


        }
        @if(isAuthenticated){

        <!-- Profile dropdown -->
        <div class="inline-block relative text-left">
          <!-- <div (click)="op.toggle($event)" class="flex items-center">
        <div>
          <img alt="profile image" [src]=" '../../../../assets/user-avatar-profile.png'"
            class="object-cover w-4 h-4 rounded-full cursor-pointer md:w-6 md:h-6">
        </div>
        <div class="px-2 py-1 md:px-4 md:py-2">
          <p class="text-xs font-medium text-gray-900 md:text-sm">
            {{ user?.name?.split(' ')?.[0] || 'Guest' }}
          </p>
          <p class="text-xs text-gray-500 md:text-sm">{{user?.role}}</p>
        </div>
        <div class="px-2 py-1 md:px-4 md:py-2">
          <span class="pi pi-chevron-down"></span>
        </div>
      </div>  -->

          <div (click)="op.toggle($event)" class="flex items-center">
            <div class="psx-2 py-1 md:psx-4 md:py-2"></div>
            <!-- <span class="text-vendblocblue dark:text-white pi pi-user" style="font-size: 1.5rem"></span> -->
            <svg-icon [src]="USER_ICON"></svg-icon>

          </div>

          <div>

          </div>
          <!-- </div> -->

          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div class="flex flex-col py-1" role="none">
                <!-- Add user info section -->
                <div class="px-2 py-2 flex items-center gap-2">
                  <svg-icon [src]="USER_ICON"></svg-icon>
                  <div class="">
                    <h3 class="text-sm font-semibold text-vendblocDark2">{{user?.name}}</h3>
                    <!-- <p class="text-sm text-gray-500">{{user?.email}}</p> -->
                  </div>
                </div>

                <!-- Existing menu items -->

                <a routerLink="/my-orders"
                  class="inline-flex items-center gap-2 px-2 py-2 text-sm text-vendblocDark2 hover:bg-gray-100"
                  role="menuitem">
                  <svg-icon [src]="ORDER_ICON"></svg-icon>
                  <h3 class="text-vendblocDark2 text-sm font-semibold">
                    Orders
                  </h3>
                </a>
                <hr class="bg-gray-500 px-2">
                <div (click)="logout()"
                  class="pt-3 inline-flex items-center gap-2 px-2 py-2 text-sm text-vendblocDark2 cursor-pointer hover:bg-gray-100"
                  role="menuitem">
                  <svg-icon [src]="LOGOUT_ICON"></svg-icon>
                  <h3 class="text-vendblocred text-sm font-semibold">
                    Logout
                  </h3>
                </div>
              </div>
            </ng-template>
          </p-overlayPanel>
        </div>
        } @else {


        @if(currentRoute !== '/login' && currentRoute !== '/register' && currentRoute !== '/forgot-password' &&
        currentRoute !== '/request-verification'){
        <!-- Show both buttons on other pages -->

        <a class="block border border-vendblocprimayGrey3 text-vendblocpurple auth_button bg-vendbloclight"
          routerLink="/register">Register</a>
        <a class="block text-white border-gray-500 auth_button bg-vendblocpurple" routerLink="/login">Login</a>
        } @else {
        <!-- Show only the opposite button on login/register pages -->
        <!-- <a class="block text-white border-gray-500 auth_button bg-vendblocpurple"
      [routerLink]="currentRoute === '/login' ? '/register' : '/login'">
      {{currentRoute === '/login' ? 'Register' : 'Login'}}
    </a> -->
        }

        }

        <div>
          @if(darkModeService.darkModeSignal() === "dark"){
          <span class="relative cursor-pointer pi pi-moon text-vendblocblue dark:text-white" style="font-size: 25px;"
            (click)="toggleDarkMode()">
          </span>
          } @else {
          <span class="relative cursor-pointer pi pi-sun text-vendblocblue dark:text-white" style="font-size: 25px;"
            (click)="toggleDarkMode()">
          </span>
          }
        </div>

      </div>
    </div>
  </div>


  <!-- MOBILE HEADER -->

  <div [class.hidden]="!isMobile" class="flex items-center justify-between">
    @if(showLogo){
    <div>
      <a routerLink="/">
        <div class="flex justify-center items-center">
          <img [src]="darkModeService.darkModeSignal() === 'dark' ? LIGHT_MODE_LOGO : DARK_MODE_LOGO" class="h-6 md:h-8"
            alt="vendbloc_logo">
        </div>
      </a>
    </div>
    }

    <div>

    </div>

    <div class="flex items-center gap-x-5">
      @if(showCart){
      <!-- Mobile cart icon (clickable) -->
      <!-- relative cursor-pointer pi pi-shopping-cart text-vendblocblue dark:text-white md:cursor-default -->
      <div class="relative cursor-pointer md:cursor-default" (click)="isMobile && cartEvent()" style="font-size: 25px;">
        <p-badge class="absolute -right-3 -top-6" [value]="cartCount" [style]="{'background-color':'#FFB800'}"
          severity="warning"></p-badge>
        <svg-icon [svgStyle]="{ 'width.px':20 }" [src]="SHOPPING_CART_ICON"></svg-icon>
      </div>


      }
      @if(isAuthenticated){
      <div (click)="op.toggle($event)" class="flex items-center">
        <div class="psx-2 py-1 md:psx-4 md:py-2"></div>
        <!-- <span class="text-vendblocblue dark:text-white pi pi-user" style="font-size: 1.5rem"></span> -->
        <svg-icon [svgStyle]="{ 'width.px':20 }" [src]="USER_ICON"></svg-icon>

      </div>
      }
      <div>
        @if(darkModeService.darkModeSignal() === "dark"){
        <span class="relative cursor-pointer pi pi-moon text-vendblocblue dark:text-white" style="font-size: 15px;"
          (click)="toggleDarkMode()">
        </span>
        } @else {
        <span class="relative cursor-pointer pi pi-sun text-vendblocblue dark:text-white" style="font-size: 15px;"
          (click)="toggleDarkMode()">
        </span>
        }
      </div>

      <div class="bg-menu p-2 rounded-[4px]" (click)="mobileMenuOpenState.set(!mobileMenuOpenState())">

        @if(!mobileMenuOpenState()){
        <svg-icon [src]="MENU_OPEN_ICON"></svg-icon>

        }

        @if(mobileMenuOpenState()){
        <svg-icon [src]="MENU_CLOSE_ICON"></svg-icon>

        }
      </div>
    </div>
  </div>




</header>

@if(mobileMenuOpenState()){

<div class="xl:hidden fixed top-14 z-[9999] px-[30px] py-8 w-full bg-primary shadow-md text-primary">

  <div class="flex flex-col gap-6">
    <a class="text-primary hover:bg-[#F5F5F5]" routerLinkActive="route-active" routerLink="/about">About Vendbloc</a>
    <a class="text-primary" routerLinkActive="route-active" routerLink="/explore">Restaurant</a>
    <a class="text-primary" routerLinkActive="route-active" routerLink="/faqs">FAQS</a>
    @if(!isAuthenticated){
    <div class="flex flex-row gap-3">
      <a class="border border-vendblocprimayGrey3 text-vendblocpurple auth_button bg-vendbloclight"
        routerLink="/register">Register</a>
      <a class="text-white border-gray-500 auth_button bg-vendblocpurple" routerLink="/login">Login</a>
    </div>
    }
  </div>




</div>

}

<p-overlayPanel #op>
  <ng-template pTemplate="content">
    <div class="flex flex-col py-1" role="none">
      <!-- Add user info section -->
      <div class="px-2 py-2 flex items-center gap-2">
        <svg-icon [src]="USER_ICON"></svg-icon>
        <div class="">
          <h3 class="text-sm font-semibold text-vendblocDark2">{{user?.name}}</h3>
          <!-- <p class="text-sm text-gray-500">{{user?.email}}</p> -->
        </div>
      </div>

      <!-- Existing menu items -->

      <a routerLink="/my-orders"
        class="inline-flex items-center gap-2 px-2 py-2 text-sm text-vendblocDark2 hover:bg-gray-100" role="menuitem">
        <svg-icon [src]="ORDER_ICON"></svg-icon>
        <h3 class="text-vendblocDark2 text-sm font-semibold">
          Orders
        </h3>
      </a>
      <hr class="bg-gray-500 px-2">
      <div (click)="logout()"
        class="pt-3 inline-flex items-center gap-2 px-2 py-2 text-sm text-vendblocDark2 cursor-pointer hover:bg-gray-100"
        role="menuitem">
        <svg-icon [src]="LOGOUT_ICON"></svg-icon>
        <h3 class="text-vendblocred text-sm font-semibold">
          Logout
        </h3>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>


<p-dialog header="Contact Us" [(visible)]="complaintVisible" position="top" [modal]="true"
  [breakpoints]="{ '1000px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false"
  (onHide)="handleDialogClose()">
  <form [formGroup]="createComplaintForm" (submit)="createComplaint()">
    <div class="flex flex-col mt-1 space-y-2 w-full">
      <app-text-input-field [formSubmitted]="complaintFormSubmitted" ngDefaultControl inputType="email" label="Email"
        inputId="email" placeHolder="Enter Your email"
        [formControl]="createComplaintForm.controls.email"></app-text-input-field>
      <app-text-input-field [formSubmitted]="complaintFormSubmitted" ngDefaultControl inputType="text" label="Phone"
        inputId="phone" placeHolder="Enter Your phone number"
        [formControl]="createComplaintForm.controls.phone"></app-text-input-field>
      <app-text-input-field [formSubmitted]="complaintFormSubmitted" ngDefaultControl inputType="text" label="Subject"
        inputId="subject" placeHolder="Enter the subject of your message"
        [formControl]="createComplaintForm.controls.subject"></app-text-input-field>
      <app-text-input-field [formSubmitted]="complaintFormSubmitted" ngDefaultControl inputType="text" label="Message"
        inputId="message" placeHolder="Enter your message"
        [formControl]="createComplaintForm.controls.message"></app-text-input-field>
      <div class="flex justify-end items-end space-x-3 w-full">
        <button type="button" class="px-6 py-2 text-gray-700 bg-gray-200 rounded-sm" (click)="toggleComplaint()">
          Cancel
        </button>
        <button class="px-6 py-2 text-white rounded-sm bg-vendblocpurpleb" [disabled]="disableComplaintSubmission"
          type="submit">
          Submit
        </button>
      </div>
    </div>
  </form>
</p-dialog>