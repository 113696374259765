<div class="flex">
    <!-- Sidebar -->
    <div [ngClass]="{
            'w-[14%] 2xl:w-[12%]': !isSidebarCollapsed && isLargeScreen,
            'w-[6%]': isSidebarCollapsed || !isLargeScreen,
            'w-[2%]': isSidebarCollapsed && isLargeScreen
         }"
        class="flex relative flex-col flex-grow px-4 py-4 min-h-screen transition-all duration-300 bg-vendblocpurple 2xl:px-8">
        <!-- Toggle Button - Only show on large screens -->
        <div class="hidden absolute top-8 -right-8 p-2 cursor-pointer bg-vendblocgrey lg:block"
            (click)="toggleSidebar()">
            @if(isSidebarCollapsed){
            <span class="pi pi-angle-right text-vendblocpurple"></span>
            }
            @else{
            <span class="pi pi-angle-left text-vendblocpurple"></span>
            }
        </div>

        <!-- Always show collapsed version on small screens, or when manually collapsed on large screens -->
        @if (isSidebarCollapsed || !isLargeScreen) {
        <div (click)="navigate($event,'/')" class="flex justify-center py-4 mb-8 cursor-pointer">
            <img src="../../../../assets/logowithoutname.png" class="h-6" alt="logo">
        </div>
        <div class="flex flex-col gap-5 items-center mt-4">
            <!-- <span class="text-xl font-medium text-white opacity-50 lg:text-2xl">M</span> -->
            <span class="text-xl text-white cursor-pointer lg:text-2xl pi pi-home"
                (click)="navigate($event,'dashboard')" pTooltip="Dashboard" tooltipPosition="right"></span>
            <span class="text-xl text-white cursor-pointer lg:text-2xl pi pi-shop"
                (click)="navigate($event,'my-outlets')" pTooltip="My Businesses" tooltipPosition="right"></span>
            <span class="text-xl text-white cursor-pointer lg:text-2xl pi pi-clipboard"
                (click)="navigate($event,'my-orders')" pTooltip="Orders" tooltipPosition="right"></span>
            <!-- <span class="text-xl text-white cursor-pointer lg:text-2xl pi pi-truck" 
                  (click)="navigate($event,'dispatchers')"
                  pTooltip="Dispatchers"
                  tooltipPosition="right"></span> -->
            <!-- <span class="text-xl text-white cursor-pointer lg:text-2xl pi pi-box" 
                  (click)="navigate($event,'pickup')"
                  pTooltip="Pick-Ups"
                  tooltipPosition="right"></span> -->

            <!-- <span class="text-2xl font-medium text-white opacity-50">S</span> -->
            <span class="text-2xl text-white cursor-pointer pi pi-cog" (click)="navigate($event,'settings')"
                pTooltip="Settings" tooltipPosition="right"></span>
            <span class="text-2xl text-white cursor-pointer pi pi-info-circle" (click)="navigate($event,'support')"
                pTooltip="Support" tooltipPosition="right"></span>
            <span class="text-2xl text-white cursor-pointer pi pi-sign-out" (click)="logout()" pTooltip="Logout"
                tooltipPosition="right"></span>
        </div>
        }
        @else {
        <div (click)="navigate($event,'/')" class="flex justify-center py-4 mb-8 cursor-pointer">
            <img src="../../../../assets/logfav.png" class="h-8" alt="logo">
        </div>
        <!-- Menu Section -->
        <div class="space-y-4">
            <span class="text-xl font-medium text-white opacity-50">Menu</span>
            <app-admin-side-menu link="dashboard" (click)="navigate($event,'dashboard')" textColor="white"
                iconColor="white" icon="home" menuName="Dashboard"></app-admin-side-menu>
            @if(userRole === (roleEnum.STORE_OWNER || roleEnum.UNCLE_SHENOR)){

            <app-admin-side-menu [link]="'my-outlets'" (click)="navigate($event,'my-outlets')" textColor="white"
                iconColor="white" icon="shop" menuName="My Businesses"></app-admin-side-menu>
            }
            <app-admin-side-menu [link]="'my-orders'" (click)="navigate($event,'my-orders')" textColor="white"
                iconColor="white" icon="clipboard" menuName="Orders"></app-admin-side-menu>

            <!-- <app-admin-side-menu (click)="navigate($event,'dispatchers')" textColor="white" iconColor="white"
                icon="truck" menuName="Dispatchers"></app-admin-side-menu>
            <app-admin-side-menu (click)="navigate($event,'pickup')" textColor="white" iconColor="white" icon="box"
                menuName="Pick-Ups"></app-admin-side-menu> -->
        </div>


        <!-- System Section -->
        <div class="mt-8 space-y-4">
            <span class="text-xl font-medium text-white opacity-50">System</span>
            <app-admin-side-menu (click)="navigate($event,'settings')" textColor="white" iconColor="white" icon="cog"
                menuName="Settings"></app-admin-side-menu>
            <app-admin-side-menu (click)="navigate($event,'support')" textColor="white" iconColor="white"
                icon="info-circle" menuName="Support"></app-admin-side-menu>
            <app-admin-side-menu (click)="logout()" textColor="white" iconColor="white" icon="sign-out"
                menuName="Logout"></app-admin-side-menu>
        </div>
        }
    </div>

    <!-- Main Content -->
    <div [ngClass]="{
            'w-[80%] 2xl:w-[90%]': !isSidebarCollapsed && isLargeScreen,
            'w-[88%]': isSidebarCollapsed || !isLargeScreen
         }" class="flex-grow h-auto min-h-[500px] flex flex-col px-4 2xl:px-20 py-4 transition-all duration-300">
        <div>
            <app-header [dashboardHeader]="true" [showLogo]="false"></app-header>
        </div>
        <ng-content class="dark:bg-vendblocprimayDark"></ng-content>
    </div>
</div>