import { Component, EventEmitter, inject, Input, OnDestroy, Output, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { environment } from '@environments/environment';
import { AuthService, LoadingService } from '@data-access/services';
import { Subject, first, takeUntil } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CreateComplaint, ResponseDTO, User } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { MenubarModule } from 'primeng/menubar';
import { TextInputFieldComponent } from "../../shared/text-input-field/text-input-field.component";
import { MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { DarkModeService } from '@data-access/services/dark-mode/dark-mode.service';
import { Assets } from '@components/shared/assets';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
        RouterLink, CommonModule, MenubarModule, OverlayPanelModule, ButtonModule, DialogModule, ReactiveFormsModule,
    TextInputFieldComponent, BadgeModule, AngularSvgIconModule
],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})


export class HeaderComponent implements OnDestroy {

  DARK_MODE_LOGO = Assets.LOGOS.DARK_MODE_LOGO;
  LIGHT_MODE_LOGO = Assets.LOGOS.LIGHT_MODE_LOGO;
  SHOPPING_CART_ICON = Assets.ICONS.SHOPPING_CART;
  USER_ICON = Assets.ICONS.USER_ICON;
  ORDER_ICON = Assets.ICONS.ORDER_ICON;
  LOGOUT_ICON = Assets.ICONS.LOGOUT_ICON;
  MENU_OPEN_ICON = Assets.ICONS.MENU_OPEN_ICON;
  MENU_CLOSE_ICON = Assets.ICONS.MENU_CLOSE_ICON;

  items: MenuItem[] | undefined;
  isAuthenticated = true;
  profileDropDown = false;
  name: string = environment.me;
  private destroy$ = new Subject<void>();
  complaintVisible: boolean = false;
  disableComplaintSubmission: boolean= false;
  complaintFormSubmitted: boolean = false;
  currentRoute: string = '';
  user: User | null = null;
  @Input() showCart: boolean = false;
  @Input() cartCount: string = '0';
  @Output() emitCartEvent = new EventEmitter<void>();
  @Input() showLogo: boolean = true; 
  @Input() dashboardHeader: boolean = false; 
  isMobile: boolean = false;

  mobileMenuOpenState = signal(false);


  darkModeService = inject(DarkModeService);

  constructor(private authService: AuthService, private router: Router,private toastr: ToastrService, private loadingService:LoadingService) {
    this.authService.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.isAuthenticated = result;
    });
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
    this.checkScreenSize();
    window.addEventListener('resize', () => this.checkScreenSize());
    console.log(this.isMobile)
  }

  toggleDarkMode(){
    this.darkModeService.updateDarkMode();
  }

  cartEvent(){
    this.emitCartEvent.emit();
  }

  toggleComplaint(){
    this.complaintVisible = !this.complaintVisible;
    if(!this.complaintVisible){
      this.createComplaintForm.reset();
    }
  }

  navigate(route:string){
    this.loadingService.show()
    this.router.navigate([route]);
  }

  createComplaintForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(5)]),
    email: new FormControl("", [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
    subject: new FormControl('', [Validators.required, Validators.minLength(5)]),
    message: new FormControl('', [Validators.required, Validators.minLength(10)])
  })

  createComplaint() {
    this.complaintFormSubmitted = true;
    this.disableComplaintSubmission = !this.disableComplaintSubmission;
      if(!this.createComplaintForm.valid)return;
      const formValue = this.createComplaintForm.value;
      const data: CreateComplaint = {
        name: formValue.name as string,
        email: formValue.email as string,
        phone:formValue.phone as string,
        subject:formValue.subject as string,
        message:formValue.message as string
      };
      this.authService.createComplaint(data).pipe(first()).subscribe({
        next: (response: ResponseDTO) => {
          if (response.status) {
            this.toastr.success(response.message);
            this.toggleComplaint();
          }
          else {
            this.toastr.error(response.message);
          }
        },
        error: (err) => {
          console.log(err);
          this.toastr.error("Something went wrong");
        },
      })
      this.toggleComplaint();
      this.disableComplaintSubmission = !this.disableComplaintSubmission;
    }

  toggleProfileDropdown() {
    this.profileDropDown = !this.profileDropDown;
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['login']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    window.removeEventListener('resize', () => this.checkScreenSize());
  }

  handleDialogClose() {
    // Reset the form when dialog is closed
    this.createComplaintForm.reset();
    this.complaintFormSubmitted = false;
    this.disableComplaintSubmission = false;
  }

  private checkScreenSize() {
    this.isMobile = window.innerWidth < 768; // 768px is the standard md breakpoint
  }
}