<div [ngClass]="{'bg-[#692497] rounded-md': (currentPath === link)}" class="flex hover:rounded-md hover:bg-[#692497] flex-col  sm:px-1 m-1">
    <a   #rla class="flex justify-between items-center group category_item_div" 
      >
      <div class=" flex items-center space-x-2">
        <span class="pi pi-{{icon}} text-{{iconColor}}"></span>
        <span class="text-{{textColor}} flex justify-end">{{menuName}}</span>
      </div>
      <span class="text-[{{iconColor}}] opacity-50 ">
        {{menuProp}}
      </span>
    </a>
  </div>