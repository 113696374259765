import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseDTO } from '../../../models/response/response';
import { CreateOutlet, CreateOutletDeliveryLocation, Outlet } from '../../../models/outlet/outlet';
import { LocalService } from '../local/local.service';
import { WorkerCreate } from '@models/worker/worker';
import { environment } from '@environments/environment';
import { TimeSlot } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class OutletService {
  private outletSubject = new BehaviorSubject<Outlet | null>(this.getInitialCurrentOutletState());
  private outlets$ = new BehaviorSubject<Outlet[]>([]);

  outlet$: Observable<Outlet | null> = this.outletSubject.asObservable();

  constructor(private http: HttpClient, private local: LocalService) { }
  private baseUrl: string = environment.baseUrl;

  public getOutlets(): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/outlets');
  }

  public getOutletsByUser(userId: string): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/outlets/get-by-user/' + userId);
  }

  public getOutlet(id: number): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/outlets/' + id);
  }

  public getOutletBySlug(slug: string): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/outlets/get-by-slug/' + slug);
  }

  public getOutletCustomers(id: number): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/outlets/get-customers/' + id);
  }

  public getOutletWorkers(id: number): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/workers/get-by-outlet/' + id);
  }

  updateOutletTimeSlot(timeSlot: TimeSlot): Observable<ResponseDTO> {
    return this.http.post<ResponseDTO>(this.baseUrl + 'api/outlets/opening-closing', timeSlot);
  } 

  public createOutlet(model: CreateOutlet, file:File): Observable<ResponseDTO> {
    const formData = new FormData();

    formData.append('name', model.name);
    formData.append('currencyId', model.currencyId.toString());
    formData.append('storeId', model.storeId.toString());
    formData.append('opening', model.opening);
    formData.append('closing', model.closing);
    if (file) {
      formData.append('image', file);
    }
    return this.http.post<ResponseDTO>(this.baseUrl + 'api/outlets', formData);
  }

  public createOutletDeliveryLocation(model: CreateOutletDeliveryLocation): Observable<ResponseDTO> {
    return this.http.post<ResponseDTO>(this.baseUrl + 'api/deliveryLocation', model)
  }

  public deleteOutletDeliveryLocation(id: number): Observable<ResponseDTO> {
    return this.http.delete<ResponseDTO>(this.baseUrl + 'api/deliveryLocation/' + id)
  }

  public searchOutlet(query: string): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/outlets/search/' + query);
  }

  public uploadOutletImage(model: FormData): Observable<ResponseDTO> {
    return this.http.post<ResponseDTO>(this.baseUrl + 'api/outlets/upload-outlet-image', model)
  }

  public createOutletWorker(model: WorkerCreate): Observable<ResponseDTO> {
    return this.http.post<ResponseDTO>(this.baseUrl + 'api/workers', model)
  }

  public removeOutletWorker(id: number): Observable<ResponseDTO> {
    return this.http.delete<ResponseDTO>(this.baseUrl + 'api/workers/' + id)
  }

  public updateOutlet(model: Outlet): Observable<ResponseDTO> {
    return this.http.put<ResponseDTO>(this.baseUrl + 'api/outlets', model)
  }

  getOutletProducts(id: number): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/Product/get-by-outlet/' + id);
  }

  getOutletUnits(storeId: number): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/Unit/get-by-store/' + storeId);
  }

  getOutletProductCategories(id: number): Observable<ResponseDTO> {
    return this.http.get<ResponseDTO>(this.baseUrl + 'api/ProductCategory/get-by-outlet/' + id);
  }

  saveCurrentOutlet(outlet: Outlet) {
    this.outletSubject.next(outlet);
    this.local.saveData('currentOutlet', JSON.stringify(outlet));
  }

  private getInitialCurrentOutletState(): Outlet | null {
    const storedCurrentOutletState = this.local.getData('currentOutlet');
    return storedCurrentOutletState ? JSON.parse(storedCurrentOutletState) : null;
  }
}
