import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { adminMenu } from '@components/layouts/admin-layout/admin-layout.component';

@Component({
  selector: 'app-admin-side-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './admin-side-menu.component.html',
  styleUrl: './admin-side-menu.component.css'
})
export class AdminSideMenuComponent implements OnInit {
  @Input() menuName: string = '';
  @Input() menuProp: string | null = '';
  @Input() link: string = '';
  @Input() index!: number;
  @Input() active!: boolean;
  @Input() adminMenu!: adminMenu;
  @Input() icon: string = '';
  @Input() textColor: string = '';
  @Input() iconColor: string = '';

  currentPath!: string;


  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.currentPath = this.route.snapshot.routeConfig?.path as string;
  }

  navigate() {
    if (this.link !== '') {
      this.router.navigate([this.link])
    }
  }



}
